<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >

    <div v-if="payments !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perState"
                  :options="perStateOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50 w-50"
              />
              <label>وضعیت</label>
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="جستجو..."
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="payments"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="Nothing found !"
        >

          <template #cell(userInfo)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="45"
                    :src="`https://api.1minex.com/${data.item.userInfo.selfieFileData}`"
                    :variant="`success`"
                    :to="{ name: 'apps-users-edit', params: { id: data.item.userInfo.userId } }"
                />
              </template>
              <b-link
                  :to="{ name: 'apps-users-edit', params: { id: data.item.userInfo.userId } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.userInfo.name+' '+data.item.userInfo.familyName }}
              </b-link>
              <small class="text-muted">@{{ data.item.userId }}</small>
            </b-media>
          </template>

          <!-- Column: chat -->
          <template #cell(chat)="data">
            <b-link :to="{ name: 'apps-chats-id', params: { id: data.item.userInfo.userId } }">
              <feather-icon icon="MessageCircleIcon" size="20"/>
            </b-link>
          </template>

<!--          &lt;!&ndash; Column: Actions &ndash;&gt;-->
<!--          <template #cell(actions)="data">-->
<!--            <b-dropdown-->
<!--                variant="link"-->
<!--                no-caret-->
<!--                :right="$store.state.appConfig.isRTL"-->
<!--            >-->

<!--              <template #button-content>-->
<!--                <feather-icon-->
<!--                    icon="MoreVerticalIcon"-->
<!--                    size="16"-->
<!--                    class="align-middle text-body"-->
<!--                />-->
<!--              </template>-->
<!--              -->
<!--              <b-dropdown-item :to="{ name: 'apps-users-factors', params: { id: data.item.userId } }">-->
<!--                <feather-icon icon="ListIcon" />-->
<!--                <span class="align-middle ml-50">نمایش فاکتورها</span>-->
<!--              </b-dropdown-item>-->

<!--            </b-dropdown>-->
<!--          </template>-->

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {PaymentGetAllRequest} from "@/libs/Api/Payment";
import Helper from "@/libs/Helper";

export default {
  title:"لیست پرداخت ها - پنل ادمین",
  name: "PaymentsList",
  data(){
    return{
      payments:null,
      currentPage:1,
      totalCounts:null,
      perPage:10,
      overlay:false,
      perPageOptions:[10, 20, 30 , 40 , 50],
      perState:'',
      perStateOptions:[
        {label:'همه',value:''},
        {label:'وارد شده',value:1},
        {label:'فالو نکرده',value:2},
        {label:'آیدی اشتباه',value:3}
      ],
      myTableColumns : [
        { key: 'userInfo' ,label :'اطلاعات کاربر'},
        { key: 'userInfo.nationalId' ,label :'کد ملی'},
        { key: 'userInfo.mobile' ,label :'موبایل'},
        { key: 'userInfo.state' ,label :'وضعیت کاربر'},
        { key: 'paymentId' ,label :'شناسه پرداخت'},
        { key: 'status' ,label :'وضعیت پرداخت'},
        { key: 'amount' ,label :'مقدار پرداخت'},
        { key: 'refId' ,label :'کد پرداخت'},
        { key: 'chat',label:'چت'},
        // { key: 'actions',label:'عملیات'},
      ],
      searchQuery:'',
    }
  },
  async created() {
    await this.getPayments(this.perPage,this.currentPage,this.searchQuery,this.perState)
  },
  methods:{
    refreshData(){
      this.getPayments(this.perPage,this.currentPage,this.searchQuery)
    },
    async getPayments(count,pageNumber,searchCommand,state){
      let _this = this;
      _this.overlay = true;
      let data = {
        pageNumber:pageNumber,
        count:count,
        searchCommand:searchCommand,
        state:state
      }

      let paymentGetAllRequest = new PaymentGetAllRequest(_this);
      paymentGetAllRequest.setParams(data);
      await paymentGetAllRequest.fetch(function (content){
        _this.overlay = false;
        _this.payments = content.payments;
        _this.totalCounts = content.paymentsCount;
      },function (error){
        _this.overlay = false;
        console.log(error);
      })
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
  },
  watch:{
    perPage:function (nv,ov){
      this.getPayments(nv,this.currentPage,this.searchQuery,this.perState);
    },
    currentPage:function (nv,ov) {
      this.getPayments(this.perPage,nv,this.searchQuery,this.perState);
    },
    searchQuery:function (nv,ov){
      this.getPayments(this.perPage,this.currentPage,nv,this.perState)
    },
    perState:function (nv,ov){
      this.getPayments(this.perPage,this.currentPage,this.searchQuery,nv)
    },
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
